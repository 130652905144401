import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { logo } from './assets';
import useLogin from './useLogin';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
  const { email, setEmail, password, setPassword, errors, handleLogin, loading } = useLogin();
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
      <div className="w-full max-w-[450px] space-y-8">
        <div className="flex flex-col items-center space-y-4">
          <img
            src={logo}
            alt="CallAnalyse Logo"
            className="h-28 w-auto mb-8"
          />
          <h1 className="text-3xl md:text-4xl font-semibold tracking-tight">
            Welcome back
          </h1>
          <p className="text-base md:text-lg text-muted-foreground">
            Enter your credentials to access your account
          </p>
        </div>

        <div className="space-y-6">
          <div className="space-y-3">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full text-base md:text-lg h-12 md:h-14 ${errors.email ? 'border-destructive' : ''}`}
            />
            {errors.email && (
              <p className="text-base text-destructive">{errors.email}</p>
            )}
          </div>

          <div className="space-y-3">
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full text-base md:text-lg h-12 md:h-14 ${errors.password ? 'border-destructive' : ''}`}
            />
            {errors.password && (
              <p className="text-base text-destructive">{errors.password}</p>
            )}
          </div>

          <Button 
            className="w-full text-base md:text-lg h-12 md:h-14" 
            onClick={(e) => {
              e.preventDefault();
              handleLogin();
            }}
            disabled={loading}
          >
            {loading ? 'Signing in...' : 'Sign In'}
          </Button>
        </div>

        <div className="text-center">
          <Link 
            to="/forgot-password" 
            className="text-base md:text-lg text-primary hover:underline font-medium"
          >
            Forgot Password?
          </Link>
        </div>

        <div className="text-center">
          <span className="text-base md:text-lg text-muted-foreground">
            Don't have an account?{' '}
          </span>
          <Link 
            to="/register" 
            className="text-base md:text-lg text-primary hover:underline font-medium"
          >
            Sign up
          </Link>
        </div>

        <div className="text-center mt-4">
          <Link 
            to="/" 
            className="text-base md:text-lg text-primary hover:underline font-medium"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
