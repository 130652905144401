import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useApi } from '@/hooks/useApi';
import { UserProfile } from '@/types/global';
import { toast } from 'sonner';

interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
}

interface PasswordFormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export function useProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [passwordData, setPasswordData] = useState<PasswordFormData>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const api = useApi();
  const queryClient = useQueryClient();

  const { data: profile, isLoading } = useQuery<UserProfile>({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await api.get('/users/profile');
      return response.data;
    },
  });

  const { mutate: updateProfile } = useMutation({
    mutationFn: async (updatedProfile: { name: string }) => {
      const response = await api.patch('/users/profile', updatedProfile);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      setIsEditing(false);
    },
  });

  const { mutate: changePassword, isPending: isChangingPasswordLoading } = useMutation({
    mutationFn: async (data: ChangePasswordData) => {
      const response = await api.patch('/users/change-password', data);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Password changed successfully");
      setIsChangingPassword(false);
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.message || "Failed to change password");
    },
  });

  const handlePasswordChange = () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast.error("New passwords don't match");
      return;
    }

    if (passwordData.newPassword.length < 6) {
      toast.error("New password must be at least 6 characters");
      return;
    }

    changePassword({
      currentPassword: passwordData.currentPassword,
      newPassword: passwordData.newPassword
    });
  };

  const handleSave = () => {
    if (profile) {
      updateProfile({
        name: profile.name,
      });
    }
  };

  const togglePasswordVisibility = (field: keyof typeof showPasswords) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return {
    profile,
    isLoading,
    isEditing,
    setIsEditing,
    updateProfile: handleSave,
    isChangingPassword,
    setIsChangingPassword,
    handlePasswordChange,
    isChangingPasswordLoading,
    passwordData,
    setPasswordData,
    showPasswords,
    togglePasswordVisibility,
  };
} 