import { User } from "@/types/global";

export const getStoredUsedData = (): { user: User, token: string | null, refreshToken: string | null } => {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;
    const storedToken = localStorage.getItem('token');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    return { user: user, token: storedToken, refreshToken: storedRefreshToken };
};

export const clearStoredUsedData = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

export const setStoredUsedData = (user: User, token: string, refreshToken: string) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
};

