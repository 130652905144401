import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

type AnalysisStatus = 'pending' | 'processing' | 'completed' | 'failed';

interface AnalysisStatusBadgeProps {
    status?: string;
    className?: string;
}

export function AnalysisStatusBadge({ status, className }: AnalysisStatusBadgeProps) {
    const getStatusConfig = (status?: string) => {
        const lowercaseStatus = status?.toLowerCase() as AnalysisStatus;
        switch (lowercaseStatus) {
            case 'completed':
                return {
                    label: status,
                    variant: 'default' as const,
                    className: 'bg-green-500/15 text-green-700 hover:bg-green-500/25'
                };
            case 'processing':
                return {
                    label: status,
                    variant: 'outline' as const,
                    className: 'text-blue-700 border-blue-300 animate-pulse'
                };
            case 'failed':
                return {
                    label: status,
                    variant: 'destructive' as const,
                    className: 'bg-red-500/15 text-red-700 hover:bg-red-500/25'
                };
            case 'pending':
            default:
                return {
                    label: status,
                    variant: 'secondary' as const,
                    className: 'text-muted-foreground'
                };
        }
    };

    const config = getStatusConfig(status);

    return (
        <Badge
            variant={config.variant}
            className={cn(config.className, className)}
        >
            {config.label}
        </Badge>
    );
} 