import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";

interface DeleteCallDialogProps {
    callId: string;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onDelete: (callId: string, e: React.MouseEvent) => Promise<void>;
    isDeleting: boolean;
}

export function DeleteCallDialog({
    callId,
    isOpen,
    onOpenChange,
    onDelete,
    isDeleting,
}: DeleteCallDialogProps) {
    return (
        <AlertDialog
            open={isOpen}
            onOpenChange={onOpenChange}
        >
            <AlertDialogTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                    className="text-red-500 hover:text-red-600"
                >
                    <Trash2 className="h-4 w-4" />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="w-[90%] rounded-lg md:w-full">
                <AlertDialogHeader>
                    <AlertDialogTitle className="text-lg font-semibold">
                        Delete Call Recording
                    </AlertDialogTitle>
                    <AlertDialogDescription className="text-sm text-muted-foreground mt-2">
                        Are you sure you want to delete this call recording? This action cannot be undone 
                        and will permanently remove the recording and its analysis.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
                    <AlertDialogCancel 
                        disabled={isDeleting}
                        className="w-full sm:w-auto mt-2 sm:mt-0"
                    >
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                        onClick={(e) => onDelete(callId, e)}
                        className="w-full sm:w-auto bg-red-500 hover:bg-red-600"
                        disabled={isDeleting}
                    >
                        {isDeleting ? (
                            <div className="flex items-center justify-center gap-2">
                                <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
                                <span>Deleting...</span>
                            </div>
                        ) : (
                            "Delete Recording"
                        )}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
} 