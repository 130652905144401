import React from 'react';
import { Menu } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useSearchService } from "@/services/useSearchService";
import { Logo } from '@/components/icons/Logo';
import { DesktopSearch } from './DesktopSearch';
import { MobileSearch } from './MobileSearch';
import { UserMenu } from './UserMenu';

interface AppBarProps {
  toggleSidebar: () => void;
}

export const AppBar: React.FC<AppBarProps> = ({ toggleSidebar }) => {
  const {
    searchTerm,
    setSearchTerm,
    results: searchResults,
    isLoading: isSearching
  } = useSearchService();
  const [isSheetOpen, setIsSheetOpen] = React.useState(false);
  const searchRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && 
          !searchRef.current.contains(event.target as Node) && 
          searchTerm.length < 2) {
        setSearchTerm('');
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSearchTerm, searchTerm]);

  return (
    <header className="sticky top-0 z-[99] w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-14 items-center justify-between px-4">
        {/* Left section with menu and branding */}
        <div className="flex items-center">
          <Button
            variant="ghost"
            size="icon"
            className="mr-2"
            onClick={toggleSidebar}
          >
            <Menu className="h-5 w-5" />
          </Button>

          <div className="flex items-center gap-2 md:w-[180px]">
            <Logo height={24} width={24} />
            <span className="font-semibold text-lg hidden md:block">
              CallAnalyse
            </span>
          </div>
        </div>

        <DesktopSearch 
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isSearching={isSearching}
          searchResults={searchResults}
          searchRef={searchRef}
        />

        <div className="flex items-center">
          <MobileSearch 
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isSearching={isSearching}
            searchResults={searchResults}
            isSheetOpen={isSheetOpen}
            setIsSheetOpen={setIsSheetOpen}
          />
          <UserMenu />
        </div>
      </div>
    </header>
  );
}; 