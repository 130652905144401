import { Call, AnalysisRating, SalesAgentAnalysis, CustomerAnalysis } from '@/types/call';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { AlertCircle, Sparkles, UserCircle2, Users, MessageSquareQuote, Info, AlignLeft } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AnalysisInProgress } from './AnalysisInProgress';
import { cn } from '@/lib/utils';

interface AnalysisResultsProps {
  analysis: NonNullable<Call['analysis']>;
  call: Call;
}

interface RatingDisplayProps {
  title: string;
  rating: AnalysisRating;
}

const RatingDisplay = ({ title, rating }: RatingDisplayProps) => {
  const getScoreColor = (score: number) => {
    if (score >= 4) return "bg-green-500";
    if (score >= 3) return "bg-yellow-500";
    return "bg-red-500";
  };

  const getBackgroundColor = (score: number) => {
    if (score >= 4) return "bg-green-100";
    if (score >= 3) return "bg-yellow-100";
    return "bg-red-100";
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <span className="text-sm font-medium">{title}</span>
        <span className={cn(
          "text-sm font-semibold px-2 py-0.5 rounded-full text-white",
          getScoreColor(rating.rating)
        )}>
          {rating.rating}/5
        </span>
      </div>
      <div className={cn("h-2 w-full rounded-full", getBackgroundColor(rating.rating))}>
        <div
          className={cn(
            "h-full rounded-full transition-all duration-500",
            getScoreColor(rating.rating)
          )}
          style={{ width: `${rating.rating * 20}%` }}
        />
      </div>
      <p className="text-sm text-muted-foreground mt-1 italic">"{rating.explanation}"</p>
    </div>
  );
};

interface AnalysisSectionProps {
  title: string;
  icon: React.ReactNode;
  ratings: SalesAgentAnalysis | CustomerAnalysis;
  className?: string;
}

const AnalysisSection = ({ title, icon, ratings, className }: AnalysisSectionProps) => {
  const ratingEntries = Object.entries(ratings) as [string, AnalysisRating][];
  const averageRating = ratingEntries.reduce((acc, [_, rating]) => acc + rating.rating, 0) / ratingEntries.length;
  
  const getOverallScoreColor = (score: number) => {
    if (score >= 4) return "text-green-500 bg-green-50 border-green-200";
    if (score >= 3) return "text-yellow-500 bg-yellow-50 border-yellow-200";
    return "text-red-500 bg-red-50 border-red-200";
  };

  return (
    <Card className={className}>
      <CardHeader className="pb-2">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            {icon}
            <CardTitle className="text-lg">{title}</CardTitle>
          </div>
          <div className={cn(
            "px-4 py-2 rounded-full border font-bold text-lg flex items-center gap-2",
            getOverallScoreColor(averageRating)
          )}>
            <Sparkles className="h-4 w-4" />
            {averageRating.toFixed(1)}/5
          </div>
        </div>
      </CardHeader>
      <CardContent className="space-y-6 pt-4">
        {ratingEntries.map(([key, rating]) => (
          <RatingDisplay
            key={key}
            title={key.replace(/([A-Z])/g, ' $1').trim()}
            rating={rating}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export const AnalysisResults = ({ analysis, call }: AnalysisResultsProps) => {
  if (analysis.status === 'failed') {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          Analysis failed: {analysis.error || 'An unknown error occurred'}
        </AlertDescription>
      </Alert>
    );
  }

  if (analysis.status === 'processing') {
    return <AnalysisInProgress />;
  }

  // Helper function to extract key insights from the response
  const getKeyInsights = (response: string) => {
    const sentences = response.split(/[.!?]+/).filter(Boolean);
    return sentences.slice(0, 3).map(s => s.trim());
  };

  return (
    <div className="space-y-6">
      {/* Call Participants */}
      <Card className="border-primary/20 shadow-lg">
        <CardHeader className="border-b bg-primary/5 pb-4">
          <div className="flex items-center gap-2">
            <Users className="h-5 w-5 text-primary" />
            <CardTitle>Call Participants</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-4 grid grid-cols-2 gap-4">
          <div className="flex items-center gap-2">
            <UserCircle2 className="h-4 w-4 text-blue-500" />
            <span className="text-sm font-medium">Agent:</span>
            <span className="text-sm">{analysis.agentName || 'Unknown Agent'}</span>
          </div>
          <div className="flex items-center gap-2">
            <UserCircle2 className="h-4 w-4 text-purple-500" />
            <span className="text-sm font-medium">Customer:</span>
            <span className="text-sm">{analysis.customerName || 'Unknown Customer'}</span>
          </div>
        </CardContent>
      </Card>

      {/* Overall Response */}
      <Card className="border-primary/20 shadow-lg overflow-hidden">
        <CardHeader className="border-b bg-primary/5">
          <div className="flex items-center gap-2">
            <MessageSquareQuote className="h-5 w-5 text-primary" />
            <CardTitle>Analysis Summary</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-0">
          {/* Key Insights Section */}
          <div className="border-b bg-muted/30 p-6">
            <h4 className="font-semibold text-sm text-muted-foreground mb-4 flex items-center gap-2">
              <Sparkles className="h-4 w-4 text-primary" />
              KEY INSIGHTS
            </h4>
            <div className="space-y-3">
              {getKeyInsights(analysis.response).map((insight, index) => (
                <div key={index} className="flex items-start gap-2">
                  <div className="h-6 w-6 rounded-full bg-primary/10 text-primary flex items-center justify-center flex-shrink-0 mt-0.5">
                    {index + 1}
                  </div>
                  <p className="text-sm leading-relaxed">{insight}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Detailed Analysis */}
          <div className="p-6">
            <h4 className="font-semibold text-sm text-muted-foreground mb-4 flex items-center gap-2">
              <AlignLeft className="h-4 w-4 text-primary" />
              DETAILED ANALYSIS
            </h4>
            <div className="prose prose-sm max-w-none">
              <p className="text-sm leading-relaxed text-muted-foreground whitespace-pre-wrap">
                {analysis.response}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="grid md:grid-cols-2 gap-6">
        {/* Sales Agent Analysis */}
        <AnalysisSection
          title="Sales Agent Performance"
          icon={<UserCircle2 className="h-5 w-5 text-blue-500" />}
          ratings={analysis.salesAgent}
          className="border-blue-200 shadow-lg"
        />

        {/* Customer Analysis */}
        <AnalysisSection
          title="Customer Interaction"
          icon={<Users className="h-5 w-5 text-purple-500" />}
          ratings={analysis.customer}
          className="border-purple-200 shadow-lg"
        />
      </div>

      {/* Call Context Card */}
      <Card className="border-gray-200 shadow-lg">
        <CardHeader className="border-b bg-muted/30">
          <div className="flex items-center gap-2">
            <Info className="h-5 w-5 text-gray-500" />
            <CardTitle>Call Context</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="grid gap-4 p-6">
          <div className="space-y-2">
            <h4 className="font-medium text-sm text-muted-foreground">Context</h4>
            <p className="text-sm bg-muted/30 p-3 rounded-md">{call.callContext}</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}; 