import { useState, useEffect } from 'react';
import { useCalls } from '@/services/useCallService';
import { DateRange } from 'react-day-picker';
import { useCallService } from "@/services/useCallService";
import { useInView } from 'react-intersection-observer';
import { toast } from 'sonner';
import { Call } from "@/types/call";
import { useNavigate } from "react-router-dom";

interface SortConfig {
  field: 'callDate' | 'fileName' | 'createdAt' | 'industryCategory';
  order: 'asc' | 'desc';
}

export function useCallHistory() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [allCalls, setAllCalls] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [sort, setSort] = useState<SortConfig>({ field: 'createdAt', order: 'desc' });
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [deletingCallId, setDeletingCallId] = useState<string | null>(null);
  const [openDialogId, setOpenDialogId] = useState<string | null>(null);
  const { deleteCall } = useCallService();
  const { ref: loadMoreRef, inView } = useInView();


  const { data, isLoading, error } = useCalls(
    page, 
    10, 
    sort.field, 
    sort.order,
    dateRange?.from,
    dateRange?.to
  );

  // Reset state when filters change
  useEffect(() => {
    setPage(1);
    setAllCalls([]);
    setHasMore(true);
    setIsLoadingMore(false);
  }, [sort.field, sort.order, dateRange]);

  // Handle data updates
  useEffect(() => {
    if (data) {
      if (page === 1) {
        setAllCalls(data.calls);
      } else {
        setAllCalls(prev => [...prev, ...data.calls]);
      }
      setHasMore(data.calls.length > 0 && data.page < data.totalPages);
      setIsLoadingMore(false);
    }
  }, [data]);

  // Handle infinite scroll
  useEffect(() => {
    if (inView && hasMore && !isLoading && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage(prev => prev + 1);
    }
  }, [inView, hasMore, isLoading, isLoadingMore]);

  const handleSort = (field: SortConfig['field']) => {
    setSort(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleDateRangeChange = (range: DateRange | undefined) => {
    setDateRange(range);
  };

  const handleDeleteCall = async (callId: string) => {
    setDeletingCallId(callId);
    try {
      await deleteCall.mutateAsync(callId);
      setAllCalls(prev => prev.filter(call => call._id !== callId));
      toast.success('Call deleted successfully');
    } catch (error) {
      toast.error('Failed to delete call');
    } finally {
      setDeletingCallId(null);
    }
  };

  const formatDateDisplay = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const getAverageRating = (call: Call) => {
    if (!call.analysis?.salesAgent) return null;
    const ratings = Object.values(call.analysis.salesAgent).map(r => r.rating);
    const average = ratings.reduce((a, b) => a + b, 0) / ratings.length;
    return (average / 2).toFixed(1); // Convert to 5-point scale
  };

  const getScoreColor = (score: number) => {
    if (score >= 4) return "text-green-500";    
    if (score >= 3) return "text-yellow-500";   
    return "text-red-500";
  };

  const handleViewDetails = (id: string) => {
    navigate(`/calls/${id}/analysis`);
  };

  return {
    calls: allCalls,
    isLoading: isLoading && page === 1,
    isLoadingMore,
    error,
    loadMoreRef,
    hasMore,
    sort,
    handleSort,
    dateRange,
    handleDateRangeChange,
    handleDeleteCall,
    isDeletingCall: (callId: string) => deletingCallId === callId,
    formatDateDisplay,
    getAverageRating,
    getScoreColor,
    handleViewDetails,
    openDialogId,
    setOpenDialogId
  };
} 