import React from "react";

export const Logo = ({ fill = '#000000', width = '64', height = '64' }: { fill?: string, width?: string | number, height?: string | number }) => {
  return (
    <svg 
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 64 64" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M17,31c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S23.617,31,17,31z M26.949,42H18v-8.949 C22.717,33.521,26.479,37.283,26.949,42z M7,43c0-5.176,3.954-9.446,9-9.949v19.899C10.954,52.446,7,48.176,7,43z M18,52.949V44 h8.949C26.479,48.717,22.717,52.479,18,52.949z" />
        <path d="M47.954,29.741l2.021,0.897C50.514,30.878,51.087,31,51.678,31c1.12,0,2.173-0.437,2.965-1.229L56,28.414 c0.645-0.645,1-1.502,1-2.414s-0.355-1.77-1-2.414L54.414,22c-1.289-1.289-3.539-1.289-4.828,0l-0.639,0.639 C48.714,22.872,48.404,23,48.074,23h-0.148c-0.33,0-0.64-0.128-0.873-0.361l-0.691-0.691C46.128,21.714,46,21.404,46,21.074 c0-0.377,0.169-0.729,0.463-0.964l0.575-0.459C47.649,19.162,48,18.432,48,17.648c0-0.676-0.273-1.336-0.751-1.813L45.414,14 c-1.289-1.289-3.539-1.289-4.828,0l-1.357,1.357C38.437,16.149,38,17.202,38,18.345c0,0.57,0.114,1.126,0.339,1.651 C40.207,24.354,43.622,27.815,47.954,29.741z M40.643,16.771L42,15.414C42.268,15.147,42.622,15,43,15s0.732,0.147,1,0.414 l1.835,1.835C45.939,17.354,46,17.5,46,17.648c0,0.173-0.077,0.333-0.211,0.44l-0.574,0.459C44.442,19.165,44,20.086,44,21.074 c0,0.864,0.336,1.677,0.947,2.287l0.691,0.691C46.249,24.664,47.062,25,47.926,25h0.148c0.864,0,1.677-0.336,2.287-0.947L51,23.414 c0.535-0.533,1.465-0.533,2,0L54.586,25C54.853,25.268,55,25.622,55,26s-0.147,0.732-0.414,1l-1.357,1.357 c-0.632,0.632-1.623,0.818-2.441,0.453l-2.021-0.897c-3.87-1.72-6.921-4.812-8.589-8.704C40.06,18.935,40,18.644,40,18.322 C40,17.736,40.229,17.186,40.643,16.771z" />
        <path d="M52.287,35H60c1.654,0,3-1.346,3-3V12c0-1.654-1.346-3-3-3H49V1H9.586L1,9.586V59h24.697l6,4H60c1.654,0,3-1.346,3-3v-4 c0-1.654-1.346-3-3-3H49V37.055L52.287,35z M61,12v20c0,0.552-0.448,1-1,1h-8.287L45,37.195V33H34c-0.552,0-1-0.448-1-1V12 c0-0.552,0.448-1,1-1h26C60.552,11,61,11.448,61,12z M9,4.414V9H4.414L9,4.414z M33,59h16v2H33V59z M51,55h2v6h-2V55z M49,57H33v-2 h16V57z M31,60.132L27.803,58L31,55.868V60.132z M61,56v4c0,0.552-0.448,1-1,1h-5v-6h5C60.552,55,61,55.448,61,56z M47,53H31.697 l-6,4H3V11h8V3h36v6H34c-1.654,0-3,1.346-3,3v20c0,1.654,1.346,3,3,3h9v5.805l4-2.5V53z" />
        <polygon points="26,13 24,13 24,25 22,25 22,11 20,11 20,25 18,25 18,19 16,19 16,25 14,25 14,15 12,15 12,25 10,25 10,17 8,17 8,25 5,25 5,27 29,27 29,25 26,25" />
      </g>
    </svg>
  );
};
