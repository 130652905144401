import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { useProfile } from "./useProfile";
import { 
  Mail, 
  Calendar,
  Edit2,
  Save,
  X,
  Lock,
  Eye,
  EyeOff
} from "lucide-react";
import { format } from "date-fns";

export default function Profile() {
  const { 
    profile, 
    isLoading, 
    isEditing, 
    setIsEditing, 
    updateProfile,
    isChangingPassword,
    setIsChangingPassword,
    handlePasswordChange,
    isChangingPasswordLoading,
    passwordData,
    setPasswordData,
    showPasswords,
    togglePasswordVisibility
  } = useProfile();

  if (isLoading || !profile) {
    return <div className="p-4 md:p-8"><Skeleton className="h-[600px] w-full" /></div>;
  }

  return (
    <div className="p-4 md:p-8 max-w-4xl mx-auto">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 md:mb-8">
        <h1 className="text-2xl md:text-3xl font-bold">Profile</h1>
        {!isEditing ? (
          <Button onClick={() => setIsEditing(true)} variant="outline" className="w-full sm:w-auto">
            <Edit2 className="h-4 w-4 mr-2" />
            Edit Profile
          </Button>
        ) : (
          <div className="flex gap-2 w-full sm:w-auto">
            <Button onClick={updateProfile} variant="default" className="flex-1 sm:flex-initial">
              <Save className="h-4 w-4 mr-2" />
              Save
            </Button>
            <Button onClick={() => setIsEditing(false)} variant="outline" className="flex-1 sm:flex-initial">
              <X className="h-4 w-4 mr-2" />
              Cancel
            </Button>
          </div>
        )}
      </div>

      <Card>
        <CardContent className="pt-6">
          <div className="flex flex-col md:flex-row gap-6 items-center md:items-start">
            <Avatar className="w-24 h-24 md:w-32 md:h-32">
              <AvatarFallback>{profile.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <div className="flex-1 space-y-4 w-full text-center md:text-left">
              <div className="space-y-2">
                {isEditing ? (
                  <Input 
                    defaultValue={profile.name} 
                    className="text-xl md:text-2xl font-bold"
                    onChange={(e) => {
                      if (profile) {
                        profile.name = e.target.value;
                      }
                    }}
                  />
                ) : (
                  <h2 className="text-xl md:text-2xl font-bold">{profile.name}</h2>
                )}
                <Badge variant={profile.isEmailVerified ? "default" : "secondary"}>
                  {profile.isEmailVerified ? "Verified" : "Unverified"}
                </Badge>
              </div>
              <div className="grid gap-4">
                <div className="flex items-center gap-2">
                  <Mail className="h-4 w-4 text-muted-foreground" />
                  <span className="truncate">{profile.email}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <span>Joined {format(new Date(profile.createdAt), 'PP')}</span>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="mt-6">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle className="text-lg font-semibold">Password Settings</CardTitle>
            {!isChangingPassword ? (
              <Button 
                onClick={() => setIsChangingPassword(true)} 
                variant="outline"
                className="w-full sm:w-auto"
              >
                <Lock className="h-4 w-4 mr-2" />
                Change Password
              </Button>
            ) : (
              <Button 
                onClick={() => setIsChangingPassword(false)} 
                variant="outline"
                className="w-full sm:w-auto"
              >
                <X className="h-4 w-4 mr-2" />
                Cancel
              </Button>
            )}
          </CardHeader>
          {isChangingPassword && (
            <CardContent>
              <div className="space-y-4">
                <div className="relative">
                  <Input
                    type={showPasswords.currentPassword ? "text" : "password"}
                    placeholder="Current Password"
                    value={passwordData.currentPassword}
                    onChange={(e) => setPasswordData(prev => ({
                      ...prev,
                      currentPassword: e.target.value
                    }))}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute right-2 top-1/2 -translate-y-1/2 h-8 w-8"
                    onClick={() => togglePasswordVisibility('currentPassword')}
                  >
                    {showPasswords.currentPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </Button>
                </div>
                <div className="relative">
                  <Input
                    type={showPasswords.newPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={passwordData.newPassword}
                    onChange={(e) => setPasswordData(prev => ({
                      ...prev,
                      newPassword: e.target.value
                    }))}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute right-2 top-1/2 -translate-y-1/2 h-8 w-8"
                    onClick={() => togglePasswordVisibility('newPassword')}
                  >
                    {showPasswords.newPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </Button>
                </div>
                <div className="relative">
                  <Input
                    type={showPasswords.confirmPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    value={passwordData.confirmPassword}
                    onChange={(e) => setPasswordData(prev => ({
                      ...prev,
                      confirmPassword: e.target.value
                    }))}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute right-2 top-1/2 -translate-y-1/2 h-8 w-8"
                    onClick={() => togglePasswordVisibility('confirmPassword')}
                  >
                    {showPasswords.confirmPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </Button>
                </div>
                <Button 
                  onClick={handlePasswordChange}
                  disabled={isChangingPasswordLoading}
                  className="w-full sm:w-auto"
                >
                  {isChangingPasswordLoading ? "Changing..." : "Update Password"}
                </Button>
              </div>
            </CardContent>
          )}
        </Card>
      </div>
    </div>
  );
} 