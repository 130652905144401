import React from 'react';
import { Sidebar } from './Sidebar';
import { AppBar } from '@/layouts/Auth/AppBar';
import { SidebarProvider, useSidebar } from '@/contexts/SidebarContext';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Outlet } from 'react-router-dom';

const AuthLayoutContent: React.FC = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <div className="flex flex-col h-screen">
      <div className="no-print">
        <AppBar toggleSidebar={toggleSidebar} />
      </div>
      
      <div className="flex flex-1 overflow-hidden">
        <div className="no-print">
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
        
        <main className="flex-1 min-w-0 lg:ml-[60px]">
          <ScrollArea className="h-[calc(100vh-3.5rem)]">
            <div className="p-4">
              <Outlet />
            </div>
          </ScrollArea>
        </main>
      </div>
    </div>
  );
};

export const AuthLayout: React.FC = () => {
  return (
    <SidebarProvider>
      <AuthLayoutContent />
    </SidebarProvider>
  );
};

export default AuthLayout;
