import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import useForgotPassword from './useForgotPassword';
import { Link } from 'react-router-dom';
import { logo } from './assets'; // Import the logo

const ForgotPassword: React.FC = () => {
  const { email, setEmail, message, error, handleSubmit, isLoading } = useForgotPassword();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
      <div className="w-full max-w-[450px] space-y-8">
        <div className="flex flex-col items-center space-y-4">
          <img
            src={logo}
            alt="CallAnalyse Logo"
            className="h-28 w-auto mb-8"
          />
          <h1 className="text-3xl md:text-4xl font-semibold tracking-tight">
            Forgot Password
          </h1>
          <p className="text-base md:text-lg text-muted-foreground">
            Enter your email to receive password reset instructions
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-3">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full text-base md:text-lg h-12 md:h-14 ${error ? 'border-destructive' : ''}`}
            />
            {error && <p className="text-base text-destructive">{error}</p>}
            {message && <p className="text-base text-success">{message}</p>}
          </div>

          <Button
            type="submit"
            className="w-full text-base md:text-lg h-12 md:h-14"
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send Reset Link'}
          </Button>
        </form>

        <div className="text-center mt-4">
          <Link 
            to="/" 
            className="text-base md:text-lg text-primary hover:underline font-medium"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword; 