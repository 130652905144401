import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { Call } from '@/types/call';
import { useDebounce } from '@/hooks/useDebounce';
import { useState, useCallback } from 'react';

interface SearchResponse {
  data: Call[];
  meta: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

export const useSearchService = (initialTerm = '') => {
  const [searchTerm, setSearchTerm] = useState(initialTerm);
  const debouncedSearch = useDebounce(searchTerm, 500);
  const api = useApi();
  const queryClient = useQueryClient();

  const searchCalls = useCallback(async () => {
    if (!debouncedSearch || debouncedSearch.length < 2) return null;
    
    const { data } = await api.get<SearchResponse>(
      `/calls/search?searchTerm=${encodeURIComponent(debouncedSearch)}&limit=5`
    );
    return data;
  }, [debouncedSearch, api]);

  const searchQuery = useQuery({
    queryKey: ['calls', 'search', debouncedSearch],
    queryFn: searchCalls,
    enabled: debouncedSearch.length >= 2,
    staleTime: 30000,
    gcTime: 60000,
    retry: false,
  });

  const prefetchSearch = useCallback(async (term: string) => {
    if (term.length >= 1) {
      await queryClient.prefetchQuery({
        queryKey: ['calls', 'search', term],
        queryFn: async () => {
          const { data } = await api.get<SearchResponse>(
            `/calls/search?searchTerm=${encodeURIComponent(term)}&limit=5`
          );
          return data;
        },
        staleTime: 30000,
      });
    }
  }, [api, queryClient]);

  return {
    searchTerm,
    setSearchTerm: (term: string) => {
      setSearchTerm(term);
      prefetchSearch(term);
    },
    results: searchQuery.data?.data ?? [],
    isLoading: searchQuery.isLoading,
    isError: searchQuery.isError,
  };
}; 