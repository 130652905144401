import { Call } from "@/types/call";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { formatDateTime } from '@/utils/date';
import { DeleteCallDialog } from "./DeleteCallDialog";
import { AnalysisStatusBadge } from "./AnalysisStatusBadge";
import { CardSkeleton } from "./CardSkeleton";
import { FileAudio, ChevronRight, Clock } from 'lucide-react';
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";

interface CallCardsProps {
    calls: Call[];
    isLoading: boolean;
    handleDelete: (callId: string, e: React.MouseEvent) => Promise<void>;
    isDeletingCall: (callId: string) => boolean;
    openDialogId: string | null;
    setOpenDialogId: (id: string | null) => void;
    onViewDetails: (callId: string) => void;
}

export function CallCards({
    calls,
    isLoading,
    handleDelete,
    isDeletingCall,
    openDialogId,
    setOpenDialogId,
    onViewDetails,
}: CallCardsProps) {
    const getRatings = (call: Call) => {
        if (!call.analysis) return null;
        
        const agentRatings = call.analysis.salesAgent ? 
            Object.values(call.analysis.salesAgent).map(r => r.rating) : [];
        const customerRatings = call.analysis.customer ? 
            Object.values(call.analysis.customer).map(r => r.rating) : [];
        
        const agentAverage = agentRatings.length ? 
            (agentRatings.reduce((a, b) => a + b, 0) / agentRatings.length).toFixed(1) : null;
        const customerAverage = customerRatings.length ? 
            (customerRatings.reduce((a, b) => a + b, 0) / customerRatings.length).toFixed(1) : null;
            
        return { agent: agentAverage, customer: customerAverage };
    };

    const getScoreColor = (score: number) => {
        if (score >= 4) return "text-green-500 bg-green-50 border-green-200";
        if (score >= 3) return "text-yellow-500 bg-yellow-50 border-yellow-200";
        return "text-red-500 bg-red-50 border-red-200";
    };

    return (
        <div className="md:hidden space-y-3">
            {isLoading ? (
                <CardSkeleton />
            ) : calls.length === 0 ? (
                <Card className="p-4">
                    <div className="text-center text-muted-foreground">
                        No calls found
                    </div>
                </Card>
            ) : (
                <div className="space-y-3">
                    {calls.map((call: Call) => (
                        <Card 
                            key={call._id} 
                            className={cn(
                                "transition-all duration-200 hover:shadow-lg border-primary/10",
                                "hover:border-primary/30 cursor-pointer bg-gradient-to-r from-card to-primary/5"
                            )}
                            onClick={() => onViewDetails(call._id)}
                        >
                            <div className="p-3 sm:p-4 space-y-3">
                                <div className="flex justify-between items-start gap-2">
                                    <div className="min-w-0 flex-1">
                                        <div className="flex items-center gap-2 mb-2">
                                            <div className="p-1.5 sm:p-2 rounded-full bg-primary/10 flex-shrink-0">
                                                <FileAudio className="h-3 w-3 sm:h-4 sm:w-4 text-primary" />
                                            </div>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <h3 className="font-medium truncate max-w-[140px] sm:max-w-[200px]">
                                                        {call.fileName}
                                                    </h3>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p className="max-w-[250px] break-all">{call.fileName}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </div>
                                        <div className="flex items-center gap-1 text-xs sm:text-sm text-muted-foreground">
                                            <Clock className="h-3 w-3 flex-shrink-0" />
                                            <span className="truncate">
                                                {formatDateTime(call.callDate)}
                                            </span>
                                        </div>
                                    </div>
                                    <AnalysisStatusBadge status={call.analysis?.status} />
                                </div>

                                <div className="space-y-2">
                                    {call.analysis && (
                                        <div className="flex flex-wrap items-center gap-2">
                                            {getRatings(call)?.agent && (
                                                <Badge 
                                                    variant="outline" 
                                                    className={cn(
                                                        "font-semibold text-xs sm:text-sm",
                                                        getScoreColor(parseFloat(getRatings(call)!.agent!))
                                                    )}
                                                >
                                                    Agent: {getRatings(call)!.agent}/5
                                                </Badge>
                                            )}
                                            {getRatings(call)?.customer && (
                                                <Badge 
                                                    variant="outline" 
                                                    className={cn(
                                                        "font-semibold text-xs sm:text-sm",
                                                        getScoreColor(parseFloat(getRatings(call)!.customer!))
                                                    )}
                                                >
                                                    Customer: {getRatings(call)!.customer}/5
                                                </Badge>
                                            )}
                                        </div>
                                    )}
                                    
                                    {call.analysis?.salesAgent && (
                                        <div className="flex flex-wrap gap-1.5">
                                            {Object.entries(call.analysis.salesAgent)
                                                .filter(([_, rating]) => rating.rating < 7)
                                                .slice(0, 2)
                                                .map(([key]) => (
                                                    <Badge 
                                                        key={key} 
                                                        variant="secondary"
                                                        className="bg-muted/50 text-xs whitespace-normal text-center"
                                                    >
                                                        {key.replace(/([A-Z])/g, ' $1').trim()}
                                                    </Badge>
                                                ))}
                                        </div>
                                    )}
                                </div>

                                <div className="flex gap-2 pt-1">
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        className="flex-1 border-primary/20 hover:bg-primary/5 text-xs sm:text-sm h-8 sm:h-9"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onViewDetails(call._id);
                                        }}
                                    >
                                        View Details
                                        <ChevronRight className="h-3 w-3 sm:h-4 sm:w-4 ml-1" />
                                    </Button>
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <DeleteCallDialog
                                            callId={call._id}
                                            isOpen={openDialogId === call._id}
                                            onOpenChange={(open) => {
                                                if (!open && !isDeletingCall(call._id)) {
                                                setOpenDialogId(null);
                                            } else if (open) {
                                                setOpenDialogId(call._id);
                                            }
                                        }}
                                        onDelete={handleDelete}
                                        isDeleting={isDeletingCall(call._id)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            )}
        </div>
    );
} 