'use client';

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  PhoneCall,
  FileAudio,
  Clock,
  AlertCircle,
  Upload,
  CheckCircle,
  XCircle,
  ChevronRight,
  Sparkles,
  BarChart3
} from "lucide-react";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { useDashboard } from "./useDashboard";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Call } from "@/types/call";
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
  const { calls, stats, isCallsLoading } = useDashboard();
  const navigate = useNavigate();

  const getRatings = (call: Call) => {
    if (!call.analysis) return null;
    
    const agentRatings = call.analysis.salesAgent ? 
        Object.values(call.analysis.salesAgent).map(r => r.rating) : [];
    const customerRatings = call.analysis.customer ? 
        Object.values(call.analysis.customer).map(r => r.rating) : [];
    
    const agentAverage = agentRatings.length ? 
        (agentRatings.reduce((a, b) => a + b, 0) / agentRatings.length).toFixed(1) : null;
    const customerAverage = customerRatings.length ? 
        (customerRatings.reduce((a, b) => a + b, 0) / customerRatings.length).toFixed(1) : null;
        
    return { agent: agentAverage, customer: customerAverage };
  };

  const getScoreColor = (score: number) => {
    if (score >= 4) return "text-green-500";
    if (score >= 3) return "text-yellow-500";
    return "text-red-500";
  };

  const statCards = [
    {
      title: "Total Calls",
      value: stats?.totalCalls ?? 0,
      icon: PhoneCall,
      color: "bg-blue-500/10 text-blue-500"
    },
    {
      title: "Processing Analysis",
      value: stats?.processingAnalysis ?? 0,
      icon: Clock,
      color: "bg-yellow-500/10 text-yellow-500"
    },
    {
      title: "Completed Analysis",
      value: stats?.completedAnalysis ?? 0,
      icon: CheckCircle,
      color: "bg-green-500/10 text-green-500"
    },
    {
      title: "Failed Analysis",
      value: stats?.failedAnalysis ?? 0,
      icon: XCircle,
      color: "bg-red-500/10 text-red-500"
    }
  ];

  return (
    <div className="p-2 md:p-8">
      <div className="flex justify-between items-center mb-4 md:mb-8">
        <div className="space-y-1">
          <h1 className="text-2xl md:text-3xl font-bold">Dashboard</h1>
          <p className="text-muted-foreground text-sm sm:text-base">Welcome back to your call analysis overview</p>
        </div>
        <Button
          onClick={() => navigate('/upload')}
          className="flex items-center gap-2 bg-primary/90 hover:bg-primary"
          size="sm"
        >
          <Upload className="h-3 w-3 sm:h-4 sm:w-4" />
          <span className="hidden sm:inline">Upload Call</span>
          <span className="sm:hidden">Upload</span>
        </Button>
      </div>

      <div className="grid gap-3 sm:gap-4 md:gap-6 grid-cols-2 lg:grid-cols-4 mb-6">
        {statCards.map((stat, index) => (
          <Card key={index} className="relative overflow-hidden">
            <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0 p-3 sm:p-4 md:p-6">
              <CardTitle className="text-xs sm:text-sm md:text-base font-medium">
                {stat.title}
              </CardTitle>
              <div className={cn("p-1.5 sm:p-2 rounded-full", stat.color)}>
                <stat.icon className="h-3 w-3 sm:h-4 sm:w-4" />
              </div>
            </CardHeader>
            <CardContent className="p-3 sm:p-4 md:p-6 pt-0">
              <div className="text-lg sm:text-2xl md:text-3xl font-bold">{stat.value}</div>
            </CardContent>
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-primary/5 via-primary/50 to-primary/5" />
          </Card>
        ))}
      </div>

      {/* Recent Calls Section */}
      <div className="mt-4 md:mt-8">
        <div className="flex justify-between items-center mb-4 sm:mb-6">
          <div className="space-y-1">
            <h2 className="text-base sm:text-lg md:text-xl font-semibold flex items-center gap-2">
              <BarChart3 className="h-4 w-4 sm:h-5 sm:w-5 text-primary" />
              Recent Call Analysis
            </h2>
            <p className="text-xs sm:text-sm text-muted-foreground">
              Your latest analyzed calls and their performance metrics
            </p>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => navigate('/history')}
            className="flex items-center gap-1 sm:gap-2 border-primary/20 hover:bg-primary/5 text-xs sm:text-sm"
          >
            View All
            <ChevronRight className="h-3 w-3 sm:h-4 sm:w-4" />
          </Button>
        </div>

        <div className="space-y-3 sm:space-y-4">
          {isCallsLoading ? (
            <div className="space-y-3 sm:space-y-4">
              <Skeleton className="h-[120px] sm:h-[140px] w-full rounded-lg" />
              <Skeleton className="h-[120px] sm:h-[140px] w-full rounded-lg" />
            </div>
          ) : calls.length > 0 ? (
            calls.map((call: Call, index: number) => (
              <div key={call._id}>
                <Card 
                  className={cn(
                    "transition-all duration-200 hover:shadow-lg border-primary/10",
                    "hover:border-primary/30 cursor-pointer bg-gradient-to-r from-card to-primary/5"
                  )}
                  onClick={() => navigate(`/calls/${call._id}/analysis`)}
                >
                  <CardContent className="p-3 sm:p-4 md:p-6">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 sm:gap-4">
                      <div className="space-y-2 sm:space-y-3 min-w-0">
                        <div className="flex items-center gap-2">
                          <div className="p-1.5 sm:p-2 rounded-full bg-primary/10 flex-shrink-0">
                            <FileAudio className="h-3 w-3 sm:h-4 sm:w-4 text-primary" />
                          </div>
                          <span className="font-medium text-xs sm:text-sm md:text-base truncate max-w-[160px] sm:max-w-[300px] md:max-w-[400px]">
                            {call.fileName}
                          </span>
                        </div>

                        <div className="flex flex-wrap items-center gap-2 sm:gap-3 text-xs sm:text-sm">
                          <div className="flex items-center gap-1 text-muted-foreground">
                            <Clock className="h-3 w-3" />
                            <span className="truncate">
                              {format(new Date(call.callDate), 'PPp')}
                            </span>
                          </div>
                          {getRatings(call)?.agent && (
                            <Badge variant="outline" className={cn(
                              "font-semibold text-xs sm:text-sm",
                              getScoreColor(parseFloat(getRatings(call)!.agent!))
                            )}>
                              <Sparkles className="h-3 w-3 mr-1" />
                              Agent: {getRatings(call)!.agent}/5
                            </Badge>
                          )}
                          {getRatings(call)?.customer && (
                            <Badge variant="outline" className={cn(
                              "font-semibold text-xs sm:text-sm",
                              getScoreColor(parseFloat(getRatings(call)!.customer!))
                            )}>
                              <Sparkles className="h-3 w-3 mr-1" />
                              Customer: {getRatings(call)!.customer}/5
                            </Badge>
                          )}
                        </div>

                        {call.analysis?.salesAgent && (
                          <div className="flex items-start gap-1 sm:gap-2">
                            <AlertCircle className="h-3 w-3 sm:h-4 sm:w-4 text-primary mt-1 flex-shrink-0" />
                            <p className="text-xs sm:text-sm text-muted-foreground line-clamp-2">
                              {Object.values(call.analysis.salesAgent)[0].explanation}
                            </p>
                          </div>
                        )}
                      </div>

                      <Button
                        variant="outline"
                        size="sm"
                        className="sm:self-center flex-shrink-0 border-primary/20 hover:bg-primary/5 h-8 sm:h-9 text-xs sm:text-sm"
                      >
                        View Analysis
                        <ChevronRight className="h-3 w-3 sm:h-4 sm:w-4 ml-1" />
                      </Button>
                    </div>
                  </CardContent>
                </Card>
                {index < calls.length - 1 && <Separator className="my-3 sm:my-4" />}
              </div>
            ))
          ) : (
            <Card className="p-4 sm:p-6 text-center">
              <div className="text-muted-foreground text-sm">
                No calls found. Start by uploading a call recording.
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
} 