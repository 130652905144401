import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Call, AnalyzeCallResponse, CallHistoryResponse, CallUploadFormData, AnalysisStatusResponse } from '@/types/call';
import { AxiosRequestConfig } from 'axios';
import { useApi } from '@/hooks/useApi';
import dayjs from 'dayjs';

interface UploadCallParams {
  file: File;
  formData: CallUploadFormData;
  config?: AxiosRequestConfig;
  analyzeImmediately?: boolean;
}

export const useCallService = () => {
  const queryClient = useQueryClient();
  const api = useApi();

  const getCall = async (id: string): Promise<Call> => {
    const { data } = await api.get<Call>(`/calls/${id}`);
    return data;
  };

  // Upload call recording
  const uploadCall = useMutation({
    mutationFn: async ({ file, formData, config, analyzeImmediately = false }: UploadCallParams) => {
      const form = new FormData();
      form.append('file', file);
      form.append('fileType', 'audio');
      form.append('callContext', formData.callContext);
      form.append('callObjectives', formData.callObjectives || '');
      form.append('industryCategory', formData.industryCategory || '');
      form.append('customParameters', formData.customParameters || '');
      
      const { data: call } = await api.post<Call>(
        '/calls/upload', 
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          ...config,
        }
      );

      if (analyzeImmediately && call._id) {
        await api.post<AnalyzeCallResponse>(`/calls/${call._id}/analyze`);
      }

      return call;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calls'] });
    },
  });

  // Start analysis for a call
  const analyzeCall = useMutation({
    mutationFn: async (callId: string) => {
      const { data } = await api.post<AnalyzeCallResponse>(`/calls/${callId}/analyze`);
      return data;
    },
    onSuccess: (_, callId) => {
      queryClient.invalidateQueries({ queryKey: ['calls', callId] });
      queryClient.invalidateQueries({ queryKey: ['analysis', callId] });
      queryClient.invalidateQueries({ queryKey: ['calls', 'history'] });
    },
    onError: (error, callId) => {
      queryClient.removeQueries({ queryKey: ['analysis', callId] });
    }
  });

  // Add this new function to useCallService
  const getDashboardStats = async () => {
    const response = await api.get<{
      totalCalls: number;
      processingAnalysis: number;
      completedAnalysis: number;
      failedAnalysis: number;
    }>('/calls/stats/dashboard');
    return response.data;
  };

  const deleteCall = useMutation({
    mutationFn: async (callId: string) => {
      const response = await api.delete(`/calls/${callId}`);
      return response.data;
    },
    onSuccess: () => {
      // Invalidate and refetch calls query
      queryClient.invalidateQueries({ queryKey: ['calls'] });
    },
  });

  return {
    uploadCall,
    analyzeCall,
    getDashboardStats,
    deleteCall,
    getCall,
  };
};

// Get call details with proper stale time and caching
export const useCall = (callId: string) => {
  const api = useApi();
  
  return useQuery({
    queryKey: ['calls', callId],
    queryFn: async () => {
      const { data } = await api.get<Call>(`/calls/${callId}`);
      return data;
    },
    enabled: !!callId,
    staleTime: 0, // Always fetch fresh data
    gcTime: 0, // Don't cache the data
  });
};

// Get call history
export const useCalls = (
  page = 1, 
  limit = 10, 
  sortBy: 'callDate' | 'fileName' | 'createdAt' | 'industryCategory' = 'createdAt', 
  sortOrder: 'asc' | 'desc' = 'desc',
  startDate?: Date,
  endDate?: Date
) => {
  const api = useApi();
  
  return useQuery({
    queryKey: ['calls', 'history', { page, limit, sortBy, sortOrder, startDate, endDate }],
    queryFn: async () => {
      const params = new URLSearchParams({
        page: page.toString(),
        limit: limit.toString(),
        sortBy,
        sortOrder,
      });

      // Add date filters if they exist
      if (startDate) {
        // Format start date to YYYY-MM-DD using dayjs
        params.append('startDate', dayjs(startDate).format('YYYY-MM-DD'));
      }
      if (endDate) {
        // Format end date to YYYY-MM-DD using dayjs
        params.append('endDate', dayjs(endDate).format('YYYY-MM-DD'));
      }

      const { data } = await api.get<CallHistoryResponse>(
        `/calls/history?${params.toString()}`
      );
      
      return {
        calls: data.data,
        total: data.meta.total,
        page: data.meta.page,
        limit: data.meta.limit,
        totalPages: data.meta.totalPages,
      };
    },
    placeholderData: (previousData) => previousData,
  });
};

// Get analysis status with proper polling
export const useAnalysisStatus = (analysisId: string) => {
  const api = useApi();
  
  return useQuery({
    queryKey: ['analysis', analysisId],
    queryFn: async () => {
      const { data } = await api.get<AnalysisStatusResponse>(
        `/calls/analysis/${analysisId}`
      );
      
      if (data.status === 'failed') {
        throw new Error(data.error || 'Analysis failed');
      }
      
      return data;
    },
    refetchInterval: (query) => 
      query.state.data?.status === 'processing' ? 3000 : false,
    enabled: !!analysisId,
    retry: false,
    staleTime: 0,
    gcTime: 0,
  });
}; 