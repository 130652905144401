import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useAuthService } from '@/services/authService';
import { toast } from 'sonner';

const useForgotPassword = () => {
  const { forgotPassword } = useAuthService();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const { mutate, isPending: isLoading } = useMutation<
    { message: string },
    AxiosError<{ message: string }>,
    { email: string }
  >({
    mutationFn: forgotPassword,
    onSuccess: (data) => {
      setMessage(data.message);
      setError('');
      toast.success(data.message);
    },
    onError: (error) => {
      if (error.response?.status === 404) {
        setError('User not found');
      } else {
        setError(error.response?.data?.message || 'An error occurred');
      }
      toast.error(error.response?.data?.message || 'An error occurred');
      setMessage('');
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate({ email });
  };

  return {
    email,
    setEmail,
    message,
    error,
    handleSubmit,
    isLoading,
  };
};

export default useForgotPassword; 