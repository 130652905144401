import { useState, useCallback, useRef, useEffect } from 'react';
import { useApi } from '@/hooks/useApi';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

type VerificationStatus = 'loading' | 'success' | 'error';

export const useEmailVerification = () => {
  const [status, setStatus] = useState<VerificationStatus>('loading');
  const [error, setError] = useState<string | null>(null);
  const api = useApi();
  const [searchParams] = useSearchParams();
  const verificationAttempted = useRef(false);

  const verifyEmail = useCallback(async () => {
    // Prevent multiple verification attempts
    if (verificationAttempted.current) {
      return;
    }
    verificationAttempted.current = true;

    const token = searchParams.get('token');

    if (!token) {
      setStatus('error');
      setError('Verification token is missing');
      toast.error('Verification Failed', {
        description: 'Verification token is missing'
      });
      return;
    }

    try {
      await api.get(`/auth/verify-email?token=${token}`);
      setStatus('success');
      toast.success('Email Verified', {
        description: 'Your email has been successfully verified'
      });
    } catch (error: any) {
      setStatus('error');
      const errorMessage = error.response?.data?.message || 'Invalid or expired verification link';
      setError(errorMessage);
      toast.error('Verification Failed', {
        description: errorMessage
      });
    }
  }, []); // Empty dependency array since we're using refs



  useEffect(() => {
    verifyEmail();
  }, []);
  
  return {
    status,
    error,
    verifyEmail
  };
}; 