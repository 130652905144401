import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import axios from 'axios';
import { validateEmail, validatePassword } from '@/utils/toolkit';
import { useApi } from '@/hooks/useApi';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const useLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [resendingVerification, setResendingVerification] = useState(false);
  const { setAuthInfo } = useAuth();
  const api = useApi();
  const navigate = useNavigate();

  const validateInputs = () => {
    const emailValidation = validateEmail(email);
    const passwordValidation = validatePassword(password);

    setErrors({
      email: emailValidation.error ? emailValidation.errorText : '',
      password: passwordValidation.error ? passwordValidation.errorText : '',
    });

    return !emailValidation.error && !passwordValidation.error;
  };

  const handleResendVerification = async () => {
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    toast.loading('Sending verification email...', { id: 'verification-email' });
    setResendingVerification(true);

    try {
      await api.post('/auth/resend-verification', { email });
      
      toast.success('Verification Email Sent', {
        id: 'verification-email',
        description: 'Please check your email for the verification link.',
        duration: 5000
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error('Failed to send verification email', {
          id: 'verification-email',
          description: error.response?.data?.message || 'Please try again later'
        });
      }
    } finally {
      setResendingVerification(false);
    }
  };

  const handleLogin = async () => {
    if (!validateInputs()) {
      return;
    }

    setLoading(true);

    try {
      const response = await api.post('/auth/login', { email, password });
      const { token, refreshToken, data } = response.data;
      setAuthInfo(token, refreshToken, data);
      navigate('/dashboard');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;
        const status = error.response?.status;

        // Clear previous errors
        setErrors({ email: '', password: '' });

        if (status === 401) {
          if (errorData?.message?.includes('verify')) {
            toast('Email Not Verified', {
              description: 'Please verify your email before logging in.',
              action: {
                label: 'Resend Verification',
                onClick: () => {
                  return new Promise<void>((resolve) => {
                    handleResendVerification();
                    resolve();
                  });
                }
              }
            });
          } else {
            setErrors({
              email: '',
              password: 'Invalid email or password'
            });
            toast.error('Login Failed', {
              description: 'Invalid email or password'
            });
          }
        } else {
          toast.error('Login Failed', {
            description: errorData?.message || 'An error occurred during login'
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    errors,
    setErrors,
    handleLogin,
    loading,
    resendingVerification
  };
};

export default useLogin;
