import { Query, useQuery } from '@tanstack/react-query';
import { useCallService } from '@/services/useCallService';
import { Call } from '@/types/call';

export const useCallAnalysis = (id: string) => {
  const { getCall } = useCallService();
  
  const { data: call, isLoading, error } = useQuery({
    queryKey: ['call', id],
    queryFn: () => getCall(id),
    refetchInterval: (query: Query<Call, Error, Call, string[]>) => {
      return query.state.data?.analysis?.status === 'completed' || query.state.data?.analysis?.status === 'failed' ? false : 1000;
    }
  });

  return {
    call,
    isLoading,
    error,
  };
}; 