import { Call, AnalysisRating, SalesAgentAnalysis, CustomerAnalysis } from '@/types/call';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';

interface PrintableAnalysisProps {
  analysis: NonNullable<Call['analysis']>;
  call: Call;
}

interface RatingDisplayProps {
  title: string;
  rating: AnalysisRating;
}

const RatingDisplay = ({ title, rating }: RatingDisplayProps) => {
  const getScoreColor = (score: number) => {
    if (score >= 4) return "bg-green-500";
    if (score >= 3) return "bg-yellow-500";
    return "bg-red-500";
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2">
        <span className="font-medium">{title}</span>
        <span className={cn(
          "px-2 py-0.5 rounded-full text-white",
          getScoreColor(rating.rating)
        )}>
          {rating.rating}/5
        </span>
      </div>
      <p className="text-gray-600 italic">"{rating.explanation}"</p>
    </div>
  );
};

interface AnalysisSectionProps {
  title: string;
  ratings: SalesAgentAnalysis | CustomerAnalysis;
}

const AnalysisSection = ({ title, ratings }: AnalysisSectionProps) => {
  const ratingEntries = Object.entries(ratings) as [string, AnalysisRating][];
  const averageRating = ratingEntries.reduce((acc, [_, rating]) => acc + rating.rating, 0) / ratingEntries.length;

  const getOverallScoreColor = (score: number) => {
    if (score >= 4) return "text-green-600";
    if (score >= 3) return "text-yellow-600";
    return "text-red-600";
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4 border-b pb-2">
        <h3 className="text-xl font-bold">{title}</h3>
        <span className={cn(
          "font-bold",
          getOverallScoreColor(averageRating)
        )}>
          Overall: {averageRating.toFixed(1)}/5
        </span>
      </div>
      {ratingEntries.map(([key, rating]) => (
        <RatingDisplay
          key={key}
          title={key.replace(/([A-Z])/g, ' $1').trim()}
          rating={rating}
        />
      ))}
    </div>
  );
};

export const PrintableAnalysis = ({ analysis, call }: PrintableAnalysisProps) => {
  const getKeyInsights = (response: string) => {
    const sentences = response.split(/[.!?]+/).filter(Boolean);
    return sentences.slice(0, 3).map(s => s.trim());
  };

  const formattedDate = call.callDate ? format(new Date(call.callDate), 'PPp') : 'Date not available';

  return (
    <div className="print-only">
      <div className="p-8 max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold mb-6">Call Analysis Report</h1>

        {/* Call Details */}
        <div className="mb-8 p-4 border rounded-lg bg-gray-50">
          <h2 className="text-lg font-semibold mb-3">Call Details</h2>
          <div className="grid gap-2">
            <div className="grid grid-cols-3">
              <span className="text-gray-600">Call Name:</span>
              <span className="col-span-2 font-medium ml-1">{call.fileName || 'Unnamed Call'}</span>
            </div>
            <div className="grid grid-cols-3">
              <span className="text-gray-600">Date & Time:</span>
              <span className="col-span-2 font-medium ml-1">{formattedDate}</span>
            </div>
            <div className="grid grid-cols-3">
              <span className="text-gray-600">Sales Agent:</span>
              <span className="col-span-2 font-medium ml-1">{analysis.agentName || 'Unknown Agent'}</span>
            </div>
            <div className="grid grid-cols-3">
              <span className="text-gray-600">Customer:</span>
              <span className="col-span-2 font-medium ml-1">{analysis.customerName || 'Unknown Customer'}</span>
            </div>
            <div className="grid grid-cols-3">
              <span className="text-gray-600">Context:</span>
              <span className="col-span-2 font-medium ml-1">{call.callContext || 'No context provided'}</span>
            </div>
          </div>
        </div>

        {/* Key Insights */}
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Key Insights</h2>
          <div className="space-y-2">
            {getKeyInsights(analysis.response).map((insight, index) => (
              <div key={index} className="flex gap-2">
                <span className="font-bold">{index + 1}.</span>
                <p>{insight}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Detailed Analysis */}
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Detailed Analysis</h2>
          <p className="whitespace-pre-wrap text-gray-600">{analysis.response}</p>
        </div>

        {/* Performance Ratings */}
        <div className="grid gap-8">
          <AnalysisSection
            title="Sales Agent Performance"
            ratings={analysis.salesAgent}
          />
          <AnalysisSection
            title="Customer Interaction"
            ratings={analysis.customer}
          />
        </div>
      </div>
    </div>
  );
};