import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { clearStoredUsedData, getStoredUsedData, setStoredUsedData } from "@/utils/authToolkit";
import { toast } from "sonner";

// const baseURL = "https://localhost:8000";
const baseURL = "https://api.callanalyse.com/";

// Public routes that don't need token handling
const PUBLIC_ROUTES = [
  '/auth/login',
  '/auth/register',
  '/auth/resend-verification',
  '/auth/verify-email'
];

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

export const useApi = () => {
  const api: AxiosInstance = axios.create({
    baseURL,
    timeout: 100000,
  });

  api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      // Skip token handling for public routes
      if (PUBLIC_ROUTES.some(route => config.url?.includes(route))) {
        return config;
      }

      const { token } = getStoredUsedData();
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const refreshTokenAndRetry = async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;
    if (!originalRequest) {
      return Promise.reject(error);
    }

    try {
      const { refreshToken } = getStoredUsedData();
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const { data } = await axios.post(`${baseURL}/auth/refresh`, { refreshToken });
      setStoredUsedData(data.data, data.token, data.refreshToken);

      if (originalRequest.headers) {
        originalRequest.headers.Authorization = `Bearer ${data.token}`;
      }

      return api(originalRequest);
    } catch (refreshError) {
      clearStoredUsedData();
      toast.error("Session expired");
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
      return Promise.reject(refreshError);
    }
  };

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;
      
      // Skip refresh token handling for public routes
      if (originalRequest && PUBLIC_ROUTES.some(route => originalRequest.url?.includes(route))) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
        originalRequest._retry = true;
        return refreshTokenAndRetry(error);
      }
      return Promise.reject(error);
    }
  );

  return api;
};
