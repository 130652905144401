import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const GuestLayout = ({ children }: React.PropsWithChildren<{}>) => {
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if (auth?.user) {
            navigate("/dashboard", { replace: true });
        }
    }, [auth?.user, navigate]);
    return (
        <div>
            {/* Header content */}
            <main>
                {/* Render the main content of the page */}
                <Outlet />
            </main>
            <footer>
                {/* Footer content */}
            </footer>
        </div>
    )
}

export default GuestLayout;