import { Card, CardContent } from '@/components/ui/card';
export function AnalysisInProgress() {
  return (
    <Card>
      <CardContent className="p-6">
        <div className="flex flex-col items-center text-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary" />
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Analyzing Call</h3>
            <p className="text-muted-foreground">
              Please wait while we analyze your call recording.
              <br />
              This may take a few minutes.
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
} 