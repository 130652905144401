import { useNavigate } from 'react-router-dom';

const useLandingPage = () => {
  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate('/demo');
  };

  const handleGetStartedClick = () => {
    navigate('/login');
  };

  return {
    handleDemoClick,
    handleGetStartedClick,
  };
};

export default useLandingPage;