import { useState, useEffect } from 'react';
import { useCallService } from '@/services/useCallService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { AxiosProgressEvent } from 'axios';
import { CallUploadFormData } from '@/types/call';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 5MB in bytes

const DEFAULT_FORM_DATA: CallUploadFormData = {
  fileType: 'audio',
  callContext: '',
  customParameters: '{}'
};

export const useCallUpload = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formData, setFormData] = useState<CallUploadFormData>(DEFAULT_FORM_DATA);
  const { uploadCall } = useCallService();
  const navigate = useNavigate();

  const validateFile = (file: File): string | null => {
    if (file.size > MAX_FILE_SIZE) {
      return 'File size exceeds 5MB limit';
    }
    if (!file.type.startsWith('audio/')) {
      return 'Only audio files are allowed';
    }
    return null;
  };

  const handleFileSelect = (file: File | null) => {
    if (!file) {
      if (selectedFile) {
        URL.revokeObjectURL(URL.createObjectURL(selectedFile));
      }
      setSelectedFile(null);
      return;
    }

    const error = validateFile(file);
    if (error) {
      toast.error(error);
      return;
    }

    setSelectedFile(file);
  };

  useEffect(() => {
    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(URL.createObjectURL(selectedFile));
      }
    };
  }, [selectedFile]);

  const handleFormChange = (field: keyof CallUploadFormData, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const uploadFile = async (file: File) => {
    try {
      const result = await uploadCall.mutateAsync({
        file,
        formData,
        config: {
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total || 0;
            const progress = total 
              ? Math.round((progressEvent.loaded * 100) / total)
              : 0;
            setUploadProgress(progress);
          },
        },
        analyzeImmediately: true
      });

      if (result._id) {
        toast.success('Call recording uploaded successfully');
        navigate(`/calls/${result._id}/analysis`);
      } else {
        toast.error('Failed to upload call recording');
      }
    } catch (error) {
      toast.error('Failed to upload call recording. Please try again.');
      console.error('Upload error:', error);
    } finally {
      setUploadProgress(0);
    }
  };

  return {
    selectedFile,
    setSelectedFile: handleFileSelect,
    uploadProgress,
    isUploading: uploadCall.isPending,
    error: uploadCall.error?.message,
    uploadFile,
    formData,
    handleFormChange
  };
}; 