import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';
import { useCallService } from '@/services/useCallService';

interface NoAnalysisAvailableProps {
  callId: string;
}

export function NoAnalysisAvailable({ callId }: NoAnalysisAvailableProps) {
  const { analyzeCall } = useCallService();

  const handleStartAnalysis = () => {
    analyzeCall.mutate(callId);
  };

  return (
    <Card>
      <CardContent className="p-6">
        <div className="flex flex-col items-center text-center space-y-4">
          <AlertCircle className="h-12 w-12 text-muted-foreground" />
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">No Analysis Available</h3>
            <p className="text-muted-foreground">
              This call hasn't been analyzed yet. Would you like to start the analysis?
            </p>
          </div>
          <Button 
            onClick={handleStartAnalysis}
            disabled={analyzeCall.isPending}
          >
            {analyzeCall.isPending ? 'Starting Analysis...' : 'Start Analysis'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
} 