import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { formatDateTime } from "@/utils/date";
import { cn } from "@/lib/utils";
import { Search } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface MobileSearchProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  isSearching: boolean;
  searchResults: any[];
  isSheetOpen: boolean;
  setIsSheetOpen: (value: boolean) => void;
}

export const MobileSearch = ({
  searchTerm,
  setSearchTerm,
  isSearching,
  searchResults,
  isSheetOpen,
  setIsSheetOpen
}: MobileSearchProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex md:hidden">
      <Sheet 
        open={isSheetOpen} 
        onOpenChange={(open) => {
          setIsSheetOpen(open);
          if (!open) {
            setSearchTerm('');
          }
        }}
      >
        <SheetTrigger asChild>
          <Button variant="ghost" size="icon">
            <Search className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent side="top" className="w-full p-0 z-[200]">
          <SheetTitle className="sr-only">Search Calls</SheetTitle>
          <SheetDescription className="sr-only">
            Search through your call recordings and analysis
          </SheetDescription>
          <MobileSearchContent 
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isSearching={isSearching}
            searchResults={searchResults}
            setIsSheetOpen={setIsSheetOpen}
          />
        </SheetContent>
      </Sheet>
    </div>
  );
};

interface MobileSearchContentProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  isSearching: boolean;
  searchResults: any[];
  setIsSheetOpen: (value: boolean) => void;
}

const MobileSearchContent = ({
  searchTerm,
  setSearchTerm,
  isSearching,
  searchResults,
  setIsSheetOpen
}: MobileSearchContentProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full max-h-[80vh]">
      <div className="flex items-center px-4 py-2 border-b h-14">
        <Search className="h-4 w-4 mr-2 flex-shrink-0 text-muted-foreground" />
        <input
          placeholder="Search calls..."
          value={searchTerm}
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setSearchTerm(e.target.value);
            }
          }}
          className="flex-1 h-9 bg-transparent outline-none placeholder:text-muted-foreground"
        />
      </div>
      <MobileSearchResults 
        searchTerm={searchTerm}
        isSearching={isSearching}
        searchResults={searchResults}
        onSelect={(call) => {
          setIsSheetOpen(false);
          setSearchTerm('');
          setTimeout(() => {
            navigate(`/calls/${call._id}/analysis`);
          }, 100);
        }}
      />
    </div>
  );
};

interface MobileSearchResultsProps {
  searchTerm: string;
  isSearching: boolean;
  searchResults: any[];
  onSelect: (call: any) => void;
}

const MobileSearchResults = ({
  searchTerm,
  isSearching,
  searchResults,
  onSelect
}: MobileSearchResultsProps) => {
  if (searchTerm.length < 2) return null;

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="py-2">
        {isSearching ? (
          <SearchingIndicator />
        ) : searchResults.length === 0 ? (
          <NoResults />
        ) : (
          <SearchResultsList searchResults={searchResults} onSelect={onSelect} />
        )}
      </div>
    </div>
  );
};

const SearchingIndicator = () => (
  <div className="p-4 text-sm text-muted-foreground">
    <div className="flex items-center gap-2">
      <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
      Searching...
    </div>
  </div>
);

const NoResults = () => (
  <div className="p-4 text-sm text-muted-foreground">
    No results found.
  </div>
);

interface SearchResultsListProps {
  searchResults: any[];
  onSelect: (call: any) => void;
}

const SearchResultsList = ({ searchResults, onSelect }: SearchResultsListProps) => (
  <div className="space-y-1">
    {searchResults.map((call) => (
      <div
        key={call._id}
        className={cn(
          "w-full text-left px-4 py-2 hover:bg-accent cursor-pointer",
          "focus:bg-accent focus:outline-none transition-colors"
        )}
        onClick={() => onSelect(call)}
      >
        <div className="flex flex-col">
          <span>{call.fileName}</span>
          <span className="text-xs text-muted-foreground">
            {formatDateTime(call.callDate)}
          </span>
        </div>
      </div>
    ))}
  </div>
); 