import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useAuthService } from '@/services/authService';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const useResetPassword = (token: string) => {
  const { resetPassword } = useAuthService();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const { mutate, isPending: isLoading } = useMutation<
    { message: string },
    AxiosError<{ message: string }>,
    { token: string; newPassword: string }
  >({
    mutationFn: resetPassword,
    onSuccess: (data) => {
      toast.success(data.message);
      setNewPassword('');
      setConfirmPassword('');
      navigate('/login');
    },
    onError: (error) => {
      setError(error.response?.data?.message || 'An error occurred');
      toast.error(error.response?.data?.message || 'An error occurred');
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    mutate({ token, newPassword });
  };

  return {
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    handleSubmit,
    isLoading,
  };
};

export default useResetPassword; 