import { useApi } from '@/hooks/useApi';

export const useAuthService = () => {
  const api = useApi();

  const forgotPassword = async ({ email }: { email: string }): Promise<{ message: string }> => {
    const response = await api.post<{ message: string }>('/auth/forgot-password', { email });
    return response.data;
  };

  const resetPassword = async ({ token, newPassword }: { token: string; newPassword: string }): Promise<{ message: string }> => {
    const response = await api.post<{ message: string }>('/auth/reset-password', { token, newPassword });
    return response.data;
  };

  return {
    forgotPassword,
    resetPassword,
  };
}; 