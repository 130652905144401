import { useCallback, useState, useRef, useEffect } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { Upload, FileAudio, X, AlertCircle, Play, Pause } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useCallUpload } from './useCallUpload';
import { Progress } from '@/components/ui/progress';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Card, CardContent } from '@/components/ui/card';
import { toast } from 'sonner';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export default function CallUpload() {
  const {
    uploadProgress,
    isUploading,
    error,
    uploadFile,
    selectedFile,
    setSelectedFile,
    formData,
    handleFormChange
  } = useCallUpload();

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handlePlayPause = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    const handleEnded = () => setIsPlaying(false);

    audioElement?.addEventListener('ended', handleEnded);
    return () => audioElement?.removeEventListener('ended', handleEnded);
  }, []);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (acceptedFiles[0]) {
      setSelectedFile(acceptedFiles[0]);
    }
    
    if (rejectedFiles.length > 0) {
      const errorMessages = rejectedFiles.map(({ errors }) => 
        errors.map(error => error.message).join(', ')
      );
      toast.error(errorMessages.join('\n'));
    }
  }, [setSelectedFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'audio/*': ['.mp3', '.wav', '.m4a'],
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 5MB
    disabled: isUploading,
    multiple: false
  });

  return (
    <div className="container mx-auto px-4 py-6 max-w-2xl">
      <Card className="mb-6">
        <CardContent className="pt-6 space-y-6">
          <h1 className="text-xl md:text-2xl font-semibold break-words">Upload Call Recording</h1>
          
          <div
            {...getRootProps()}
            className={`
              border-2 border-dashed rounded-lg p-4 md:p-8 text-center cursor-pointer
              transition-colors duration-200 min-h-[200px] flex items-center justify-center
              ${isDragActive ? 'border-primary bg-primary/5' : 'border-gray-300 hover:border-primary/50'}
              ${isUploading ? 'pointer-events-none opacity-60' : ''}
            `}
          >
            <input {...getInputProps()} />
            
            <div className="flex flex-col items-center gap-3 w-full">
              <Upload className="h-8 w-8 md:h-12 md:w-12 text-gray-400" />
              <div className="text-sm text-gray-600 px-2">
                {isDragActive ? (
                  <p>Drop the audio file here</p>
                ) : (
                  <p className="text-sm md:text-base break-words">
                    Drag & drop an audio file here, or click to select
                  </p>
                )}
              </div>
              <div className="text-xs text-gray-400 space-y-1 px-2">
                <p>Supported formats: MP3, WAV, M4A</p>
                <p>Maximum file size: 10MB</p>
              </div>
            </div>
          </div>

          {selectedFile && (
            <div className="mt-4 p-3 border rounded-lg bg-muted/30">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2 min-w-0 flex-1">
                  <FileAudio className="h-5 w-5 flex-shrink-0 text-primary" />
                  <div className="flex flex-col sm:flex-row sm:items-center gap-1 min-w-0 flex-1">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span className="text-sm font-medium truncate max-w-[160px] sm:max-w-[300px]">
                            {selectedFile.name}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{selectedFile.name}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <span className="text-xs text-muted-foreground whitespace-nowrap">
                      ({(selectedFile.size / (1024 * 1024)).toFixed(2)} MB)
                    </span>
                  </div>
                </div>
                
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handlePlayPause}
                    className="flex-shrink-0"
                    disabled={isUploading}
                  >
                    {isPlaying ? (
                      <Pause className="h-4 w-4" />
                    ) : (
                      <Play className="h-4 w-4" />
                    )}
                  </Button>
                  
                  {!isUploading && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsPlaying(false);
                        setSelectedFile(null);
                      }}
                      className="flex-shrink-0"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              </div>

              <audio 
                ref={audioRef}
                className="hidden"
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
              >
                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                Your browser does not support the audio element.
              </audio>

              {isUploading && (
                <div className="mt-3 space-y-2">
                  <Progress value={uploadProgress} className="h-2" />
                  <p className="text-xs text-gray-500 text-center">
                    Uploading... {uploadProgress}%
                  </p>
                </div>
              )}
            </div>
          )}

          {error && (
            <Alert variant="destructive" className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription className="break-words">{error}</AlertDescription>
            </Alert>
          )}

          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="callContext" className="break-words">Call Context</Label>
              <Input
                id="callContext"
                value={formData.callContext}
                onChange={(e) => handleFormChange('callContext', e.target.value)}
                placeholder="Enter call context"
                className="min-h-[40px] break-words"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <Button
              onClick={() => selectedFile && uploadFile(selectedFile)}
              disabled={!selectedFile || isUploading}
              className="w-full sm:w-auto min-h-[40px]"
            >
              {isUploading ? 'Uploading...' : 'Upload & Analyze Call'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 