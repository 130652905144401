import { useState } from 'react';
import { useApi } from '@/hooks/useApi';
import { validateEmail, validatePassword, handleApiErrors } from '@/utils/toolkit';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Errors {
  name?: string;
  email?: string;
  password?: string;
}

const useRegister = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors: Errors = {};

    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Please enter a valid email';
    }

    if (!password) {
      newErrors.password = 'Password is required';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setLoading(true);
    try {
      await api.post('/auth/register', { name, email, password });
      
      toast.success('Registration Successful', {
        description: 'Please check your email to verify your account.'
      });
      
      // Redirect to login page after successful registration
      navigate('/login');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const errorData = error.response.data;
        
        if (error.response.status === 409) {
          setErrors({
            ...errors,
            email: 'Email already exists'
          });
          toast.error('Registration Failed', {
            description: 'This email is already registered'
          });
        } else {
          setErrors({
            name: errorData.name || '',
            email: errorData.email || '',
            password: errorData.password || ''
          });
          toast.error('Registration Failed', {
            description: 'Please check your input and try again'
          });
        }
      }
      handleApiErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    errors,
    loading,
    handleRegister,
  };
};

export default useRegister;
