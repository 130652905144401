import { useParams } from 'react-router-dom';
import { useCallAnalysis } from './useCallAnalysis';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { FileAudio, Clock, Printer } from 'lucide-react';
import { format } from 'date-fns';
import { AnalysisLoadingSkeleton } from './components/AnalysisLoadingSkeleton';
import { AnalysisResults } from './components/AnalysisResults';
import { NoAnalysisAvailable } from './components/NoAnalysisAvailable';
import { BreadcrumbNav } from '@/components/ui/BreadcrumbNav';
import { Button } from '@/components/ui/button';
import { useCallService } from '@/services/useCallService';
import { toast } from 'sonner';
import { Card, CardContent } from '@/components/ui/card';
import { PrintableAnalysis } from './components/PrintableAnalysis';

export default function CallAnalysis() {
  const { id } = useParams<{ id: string }>();
  const { call, isLoading, error } = useCallAnalysis(id!);
  const { analyzeCall } = useCallService();

  const handleStartAnalysis = async () => {
    if (!call?._id) return;
    
    try {
      await analyzeCall.mutateAsync(call._id);
      toast.success('Analysis started successfully');
    } catch (error) {
      toast.error('Failed to start analysis. Please try again.');
    }
  };

  const handlePrint = () => {
    window.print();
  };

  // Show error state if there's an error
  if (error) {
    toast.error('Failed to load call data. Please try again.');
    return (
      <div className="p-4 md:p-8 max-w-5xl mx-auto">
        <Alert variant="destructive">
          <AlertDescription>
            Failed to load call data. Please refresh the page or try again later.
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  // Show loading state
  if (isLoading || !call) {
    return <AnalysisLoadingSkeleton />;
  }

  const formattedDate = call.callDate ? format(new Date(call.callDate), 'PPp') : 'Date not available';

  const breadcrumbItems = [
    { label: 'Dashboard', href: '/' },
    { label: 'Call History', href: '/history' },
    { label: call.fileName || 'Unnamed Call', href: '' }
  ];

  return (
    <>
      {/* Printable version */}
      {call.analysis && call.analysis.status === 'completed' && (
        <PrintableAnalysis analysis={call.analysis} call={call} />
      )}

      {/* Regular view */}
      <div className="p-4 md:p-8 max-w-5xl mx-auto no-print">
        <div className="space-y-4 md:space-y-6">
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-center gap-2">
              <BreadcrumbNav items={breadcrumbItems} />
            </div>
            
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="space-y-2">
                <h1 className="text-xl md:text-2xl font-bold">Call Analysis</h1>
                <div className="flex flex-col sm:flex-row sm:items-center gap-2 text-sm text-muted-foreground">
                  <div className="flex items-center gap-2 min-w-0">
                    <FileAudio className="h-4 w-4 flex-shrink-0" />
                    <span className="truncate max-w-[180px] sm:max-w-[300px] md:max-w-[400px]">
                      {call.fileName || 'Unnamed Call'}
                    </span>
                  </div>
                  <span className="hidden sm:inline flex-shrink-0">•</span>
                  <div className="flex items-center gap-2 flex-shrink-0">
                    <Clock className="h-4 w-4" />
                    <span>{formattedDate}</span>
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                {(!call.analysis || call.analysis.status === 'failed') && (
                  <Button
                    onClick={handleStartAnalysis}
                    disabled={analyzeCall.isPending}
                  >
                    {analyzeCall.isPending ? 'Starting Analysis...' : 'Generate Analysis'}
                  </Button>
                )}
                
                {call.analysis && call.analysis.status === 'completed' && (
                  <Button
                    variant="outline"
                    onClick={handlePrint}
                    className="flex items-center gap-2"
                  >
                    <Printer className="h-4 w-4" />
                    Print Report
                  </Button>
                )}
              </div>
            </div>
          </div>

          {/* Audio Player */}
          {call.fileUrl && (
            <Card>
              <CardContent className="p-6">
                <h3 className="font-semibold mb-3">Call Recording</h3>
                <audio controls src={call.fileUrl} className="w-full">
                  <track kind="captions" />
                  Your browser does not support the audio element.
                </audio>
              </CardContent>
            </Card>
          )}

          {/* Analysis Content */}
          {call.analysis ? (
            <AnalysisResults analysis={call.analysis} call={call} />
          ) : (
            <NoAnalysisAvailable callId={call._id} />
          )}
        </div>
      </div>
    </>
  );
}