import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

interface CardSkeletonProps {
    rowCount?: number;
}

export function CardSkeleton({ rowCount = 2 }: CardSkeletonProps) {
    return (
        <div className="space-y-2">
            {Array.from({ length: rowCount }).map((_, index) => (
                <Card key={index} className="p-4 animate-pulse">
                    <div className="flex flex-col gap-3">
                        <div className="flex justify-between items-start">
                            <div className="space-y-2">
                                <Skeleton className="h-4 w-[200px]" />
                                <Skeleton className="h-3 w-[150px]" />
                            </div>
                            <Skeleton className="h-6 w-[100px] rounded-full" />
                        </div>

                        <div className="flex gap-2">
                            <Skeleton className="h-6 w-[100px] rounded-full" />
                            <Skeleton className="h-6 w-[120px] rounded-full" />
                        </div>

                        <div className="flex gap-2">
                            <Skeleton className="h-9 flex-1" />
                            <Skeleton className="h-9 w-9" />
                        </div>
                    </div>
                </Card>
            ))}
        </div>
    );
} 