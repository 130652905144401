import { Call } from "@/types/call";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronRight, FileAudio } from "lucide-react";
import { cn } from "@/lib/utils";
import { formatDateTime } from '@/utils/date';
import { DeleteCallDialog } from "./DeleteCallDialog";
import { AnalysisStatusBadge } from "./AnalysisStatusBadge";
import { TableSkeleton } from "./TableSkeleton";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";

type SortField = 'callDate' | 'fileName' | 'createdAt' | 'industryCategory';

interface CallTableProps {
    calls: Call[];
    isLoading: boolean;
    sort: { field: SortField; order: string };
    handleSort: (field: SortField) => void;
    handleDelete: (callId: string, e: React.MouseEvent) => Promise<void>;
    isDeletingCall: (callId: string) => boolean;
    openDialogId: string | null;
    setOpenDialogId: (id: string | null) => void;
    onViewDetails: (callId: string) => void;
}

export function CallTable({
    calls,
    isLoading,
    sort,
    handleSort,
    handleDelete,
    isDeletingCall,
    openDialogId,
    setOpenDialogId,
    onViewDetails,
}: CallTableProps) {
    const getRatings = (call: Call) => {
        if (!call.analysis) return null;

        const agentRatings = call.analysis.salesAgent ?
            Object.values(call.analysis.salesAgent).map(r => r.rating) : [];
        const customerRatings = call.analysis.customer ?
            Object.values(call.analysis.customer).map(r => r.rating) : [];

        const agentAverage = agentRatings.length ?
            (agentRatings.reduce((a, b) => a + b, 0) / agentRatings.length).toFixed(1) : null;
        const customerAverage = customerRatings.length ?
            (customerRatings.reduce((a, b) => a + b, 0) / customerRatings.length).toFixed(1) : null;

        return { agent: agentAverage, customer: customerAverage };
    };

    const getScoreColor = (score: number) => {
        if (score >= 4) return "text-green-500 bg-green-50";
        if (score >= 3) return "text-yellow-500 bg-yellow-50";
        return "text-red-500 bg-red-50";
    };

    if (isLoading && calls.length === 0) {
        return (
            <div className="hidden md:block rounded-md border">
                <TableSkeleton showHeader={true} />
            </div>
        );
    }

    return (
        <div className="hidden md:block rounded-md border bg-card">
            <Table>
                <TableHeader className="bg-muted/50">
                    <TableRow>
                        <TableHead
                            className="cursor-pointer hover:text-primary transition-colors"
                            onClick={() => handleSort('fileName')}
                        >
                            <div className="flex items-center gap-2">
                                File Name
                                {sort.field === 'fileName' && (
                                    <ChevronDown className={cn(
                                        "h-4 w-4",
                                        sort.order === 'desc' && "rotate-180"
                                    )} />
                                )}
                            </div>
                        </TableHead>
                        <TableHead
                            className="cursor-pointer hover:text-primary transition-colors"
                            onClick={() => handleSort('callDate')}
                        >
                            <div className="flex items-center gap-2">
                                Date
                                {sort.field === 'callDate' && (
                                    <ChevronDown className={cn(
                                        "h-4 w-4",
                                        sort.order === 'desc' && "rotate-180"
                                    )} />
                                )}
                            </div>
                        </TableHead>
                        <TableHead>Average Score</TableHead>
                        <TableHead>Key Areas</TableHead>
                        <TableHead>Analysis Status</TableHead>
                        <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {calls.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={6} className="text-center text-muted-foreground">
                                No calls found
                            </TableCell>
                        </TableRow>
                    ) : (
                        calls.map((call: Call) => (
                            <TableRow
                                key={call._id}
                                className="group hover:bg-muted/50 cursor-pointer"
                                onClick={() => onViewDetails(call._id)}
                            >
                                <TableCell>
                                    <div className="flex items-center gap-2">
                                        <div className="p-2 rounded-full bg-primary/10">
                                            <FileAudio className="h-4 w-4 text-primary" />
                                        </div>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <span className="font-medium truncate max-w-[300px] block">
                                                    {call.fileName}
                                                </span>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{call.fileName}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                                <TableCell className="text-muted-foreground">
                                    {formatDateTime(call.callDate)}
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-col gap-1">
                                        {getRatings(call)?.agent && (
                                            <Badge variant="outline" className={cn(
                                                "font-semibold",
                                                getScoreColor(parseFloat(getRatings(call)!.agent!))
                                            )}>
                                                Agent: {getRatings(call)!.agent}/5
                                            </Badge>
                                        )}
                                        {getRatings(call)?.customer && (
                                            <Badge variant="outline" className={cn(
                                                "font-semibold",
                                                getScoreColor(parseFloat(getRatings(call)!.customer!))
                                            )}>
                                                Customer: {getRatings(call)!.customer}/5
                                            </Badge>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {call.analysis?.salesAgent ? (
                                        <div className="flex flex-wrap gap-1">
                                            {Object.entries(call.analysis.salesAgent)
                                                .filter(([_, rating]) => rating.rating < 7)
                                                .slice(0, 2)
                                                .map(([key]) => (
                                                    <Badge
                                                        key={key}
                                                        variant="secondary"
                                                        className="bg-muted"
                                                    >
                                                        {key.replace(/([A-Z])/g, ' $1').trim()}
                                                    </Badge>
                                                ))}
                                        </div>
                                    ) : (
                                        'N/A'
                                    )}
                                </TableCell>
                                <TableCell>
                                    <AnalysisStatusBadge status={call.analysis?.status} />
                                </TableCell>
                                <TableCell className="text-right">
                                    <div className="flex justify-end items-center gap-2">
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            className="border-primary/20 hover:bg-primary/5"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onViewDetails(call._id);
                                            }}
                                        >
                                            View Details
                                            <ChevronRight className="h-4 w-4 ml-1" />
                                        </Button>
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <DeleteCallDialog
                                                callId={call._id}
                                                isOpen={openDialogId === call._id}
                                                onOpenChange={(open) => {
                                                    if (!open && !isDeletingCall(call._id)) {
                                                        setOpenDialogId(null);
                                                    } else if (open) {
                                                        setOpenDialogId(call._id);
                                                    }
                                                }}
                                                onDelete={handleDelete}
                                                isDeleting={isDeletingCall(call._id)}
                                            />
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </div>
    );
} 