import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  BarChart2,
  FileAudio,
  LogOut,
  X,
  History,
  User
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { toast } from "sonner";
import { LogoutModal } from "@/components/LogoutModal";

const navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: BarChart2,
  },
  {
    name: "Upload Call",
    href: "/upload",
    icon: FileAudio,
  },
  {
    name: "Call History",
    href: "/history",
    icon: History,
  },
  {
    name: "Profile",
    href: "/profile",
    icon: User,
  }
];

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

export function Sidebar({ isOpen, toggleSidebar }: SidebarProps) {
  const location = useLocation();
  const { logout } = useAuth();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  // Close sidebar on route change for mobile
  useEffect(() => {
    if (window.innerWidth < 1024 && isOpen) {
      toggleSidebar();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNavClick = () => {
    if (window.innerWidth < 1024) {
      toggleSidebar();
    }
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    toast.loading('Logging out...', { id: 'logout' });
    logout();
    toast.success('Logged out successfully', { id: 'logout' });
    setShowLogoutModal(false);
  };

  return (
    <>
      {/* Mobile overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-background/80 backdrop-blur-sm z-[100] lg:hidden"
          onClick={toggleSidebar}
        />
      )}
      
      <aside className={cn(
        "fixed inset-y-0 left-0 z-[101] lg:relative",
        "flex h-full flex-col border-r bg-background transition-all duration-300",
        isOpen ? "translate-x-0 w-[200px]" : "-translate-x-full w-0 lg:translate-x-0 lg:w-[60px]"
      )}>
        {/* Mobile Header - Always present but visibility controlled by CSS */}
        <div className={cn(
          "lg:hidden border-b p-4 flex items-center justify-between",
          "transition-opacity duration-200",
          isOpen ? "opacity-100" : "opacity-0",
          // Hide from layout when closed to prevent interaction
          !isOpen && "invisible h-0 p-0 border-none"
        )}>
          <span className="font-semibold text-lg">Call Analyse</span>
          <Button 
            variant="ghost" 
            size="icon"
            onClick={toggleSidebar}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>

        <div className="flex-1 overflow-auto py-2">
          <nav className="grid items-start px-2 gap-1">
            {navigation.map((item) => {
              const isActive = location.pathname === item.href;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={handleNavClick}
                  className={cn(
                    "flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground",
                    isActive ? "bg-accent" : "transparent",
                  )}
                >
                  <item.icon className="h-4 w-4" />
                  {isOpen && <span>{item.name}</span>}
                </Link>
              );
            })}
          </nav>
        </div>
        <div className="border-t p-2">
          <Button 
            variant="ghost" 
            className="w-full justify-start gap-2"
            onClick={handleLogoutClick}
          >
            <LogOut className="h-4 w-4" />
            {isOpen && "Logout"}
          </Button>
        </div>
      </aside>

      <LogoutModal 
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onConfirm={handleLogoutConfirm}
      />
    </>
  );
}
