import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon, BarChart3, X } from "lucide-react";
import { useCallHistory } from "./useCallHistory";
import { cn } from "@/lib/utils";
import { CallTable } from "./components/CallTable";
import { CallCards } from "./components/CallCards";
import { TableSkeleton } from "./components/TableSkeleton";
import { CardSkeleton } from "./components/CardSkeleton";
import { TooltipProvider } from "@/components/ui/tooltip";
import { BreadcrumbNav } from '@/components/ui/BreadcrumbNav';

export default function CallHistory() {
    const {
        calls,
        isLoading,
        loadMoreRef,
        hasMore,
        sort,
        handleSort,
        dateRange,
        handleDateRangeChange,
        handleDeleteCall,
        isDeletingCall,
        formatDateDisplay,
        handleViewDetails,
        openDialogId,
        setOpenDialogId
    } = useCallHistory();

    const breadcrumbs = [
      { label: "Dashboard", href: "/" },
      { label: "Call History", href: "/calls" }
    ];

    return (
        <div className="flex flex-col gap-4 md:gap-6 p-2 md:p-8">
            {/* Breadcrumbs Section */}
            <BreadcrumbNav items={breadcrumbs} />

            {/* Header Section */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                <div className="space-y-1">
                    <h1 className="text-2xl md:text-3xl font-bold flex items-center gap-2">
                        <BarChart3 className="h-6 w-6 text-primary" />
                        Call History
                    </h1>
                    <p className="text-muted-foreground">
                        View and manage your analyzed calls
                    </p>
                </div>
            </div>

            {/* Simplified Filter Section */}
            <div className="flex items-center gap-2">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className={cn(
                                "justify-start text-left font-normal",
                                !dateRange?.from && "text-muted-foreground",
                                "border-primary/20 hover:bg-primary/5"
                            )}
                        >
                            <CalendarIcon className="mr-2 h-4 w-4 text-primary" />
                            {dateRange?.from ? (
                                dateRange.to ? (
                                    <>
                                        {formatDateDisplay(dateRange.from)} - {formatDateDisplay(dateRange.to)}
                                    </>
                                ) : (
                                    formatDateDisplay(dateRange.from)
                                )
                            ) : (
                                "Filter by date"
                            )}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                            initialFocus
                            mode="range"
                            selected={dateRange}
                            onSelect={handleDateRangeChange}
                            numberOfMonths={2}
                            className="hidden md:block"
                        />
                        <Calendar
                            initialFocus
                            mode="range"
                            selected={dateRange}
                            onSelect={handleDateRangeChange}
                            numberOfMonths={1}
                            className="md:hidden"
                        />
                    </PopoverContent>
                </Popover>
                {dateRange && (
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-9 w-9 text-muted-foreground hover:text-foreground"
                        onClick={() => handleDateRangeChange(undefined)}
                    >
                        <X className="h-4 w-4" />
                    </Button>
                )}
            </div>

            {/* Calls List */}
            <div className="flex flex-col gap-4 bg-muted/30 rounded-lg p-4">
                <TooltipProvider>
                    <CallTable
                        calls={calls}
                        isLoading={isLoading}
                        sort={sort}
                        handleSort={handleSort}
                        handleDelete={handleDeleteCall}
                        isDeletingCall={isDeletingCall}
                        openDialogId={openDialogId}
                        setOpenDialogId={setOpenDialogId}
                        onViewDetails={handleViewDetails}
                    />

                    <CallCards
                        calls={calls}
                        isLoading={isLoading}
                        handleDelete={handleDeleteCall}
                        isDeletingCall={isDeletingCall}
                        openDialogId={openDialogId}
                        setOpenDialogId={setOpenDialogId}
                        onViewDetails={handleViewDetails}
                    />
                </TooltipProvider>

                {/* Loading indicator for infinite scroll */}
                {hasMore && !isLoading && (
                    <div ref={loadMoreRef} className="py-2">
                        <div className="text-center text-sm text-muted-foreground pb-2">
                            Loading more calls...
                        </div>
                        <div className="hidden md:block">
                            <TableSkeleton showHeader={false} rowCount={1} />
                        </div>
                        <div className="md:hidden">
                            <CardSkeleton rowCount={1} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
} 