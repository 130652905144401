import React from "react";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from 'sonner';
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
import Register from './pages/Register/Register';
import { AuthLayout } from './layouts/Auth/AuthLayout';
import { GuestLayout } from './layouts/GuestLayout';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import CallUpload from '@/pages/CallUpload/CallUpload';
import CallAnalysis from '@/pages/CallAnalysis/CallAnalysis';
import CallHistory from '@/pages/CallHistory/CallHistory';
import EmailVerification from './pages/EmailVerification/EmailVerification';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword';
import ResetPassword from '@/pages/ResetPassword/ResetPassword';

const queryClient = new QueryClient();

// Create a root layout that includes providers
const RootLayout = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Outlet />
        <Toaster richColors />
      </AuthProvider>
    </QueryClientProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: '/dashboard',
            element: <Dashboard />,
          },
          {
            path: '/upload',
            element: <CallUpload />,
          },
          {
            path: '/calls/:id/analysis',
            element: <CallAnalysis />,
          },
          {
            path: '/profile',
            element: <Profile />,
          },
          {
            path: '/history',
            element: <CallHistory />,
          },
        ],
      },
      {
        element: <GuestLayout />,
        children: [
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '/register',
            element: <Register />,
          },
          {
            path: '/verify-email',
            element: <EmailVerification />,
          },
          {
            path: '/forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: '/reset-password',
            element: <ResetPassword />,
          },
          {
            path: '/',
            element: <LandingPage />,
          },
        ],
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
