import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { formatDateTime } from "@/utils/date";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface DesktopSearchProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  isSearching: boolean;
  searchResults: any[];
  searchRef: React.RefObject<HTMLDivElement>;
}

export const DesktopSearch = ({
  searchTerm,
  setSearchTerm,
  isSearching,
  searchResults,
  searchRef
}: DesktopSearchProps) => {
  const navigate = useNavigate();

  return (
    <div ref={searchRef} className="hidden md:flex flex-1 mx-4 w-full max-w-2xl">
      <div className="relative w-full max-w-lg">
        <Command className="rounded-lg border">
          <div className="flex items-center relative w-full max-w-lg">
            <CommandInput
              placeholder="Search calls..."
              value={searchTerm}
              onValueChange={(value) => {
                if (value.length <= 50) {
                  setSearchTerm(value);
                }
              }}
              className="h-9 pr-10 w-full max-w-lg"
            />
            {searchTerm && (
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 absolute right-1"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
          {searchTerm.length >= 2 && (
            <CommandList className="absolute z-[200] top-[100%] left-0 right-0 bg-popover border rounded-lg mt-1 max-h-[300px] overflow-y-auto shadow-lg">
              <SearchResults 
                isSearching={isSearching}
                searchResults={searchResults}
                onSelect={(call) => {
                  navigate(`/calls/${call._id}/analysis`);
                  setSearchTerm('');
                }}
              />
            </CommandList>
          )}
        </Command>
      </div>
    </div>
  );
};

interface SearchResultsProps {
  isSearching: boolean;
  searchResults: any[];
  onSelect: (call: any) => void;
}

const SearchResults = ({ isSearching, searchResults, onSelect }: SearchResultsProps) => {
  if (isSearching) {
    return <SearchingIndicator />;
  }

  return (
    <>
      <CommandEmpty className="p-4 text-sm text-muted-foreground">
        No results found.
      </CommandEmpty>
      <CommandGroup heading="Suggestions" className="p-2">
        {searchResults.map((call) => (
          <CommandItem
            key={call._id}
            value={call.fileName}
            onSelect={() => onSelect(call)}
            className="cursor-pointer rounded-md hover:bg-accent px-2 py-1.5"
          >
            <div className="flex flex-col">
              <span className="font-medium">{call.fileName}</span>
              <span className="text-xs text-muted-foreground">
                {formatDateTime(call.callDate)}
              </span>
            </div>
          </CommandItem>
        ))}
      </CommandGroup>
    </>
  );
};

const SearchingIndicator = () => (
  <div className="p-4 text-sm text-muted-foreground">
    <div className="flex items-center gap-2">
      <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
      Searching...
    </div>
  </div>
); 