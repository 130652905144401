import { useQuery } from '@tanstack/react-query';
import { useCallService, useCalls } from '@/services/useCallService';

export function useDashboard() {
  const callService = useCallService();
  const { data: callsData, isLoading: isCallsLoading } = useCalls(1, 5); // Get first 5 recent calls

  const {
    data: stats,
    isLoading: isStatsLoading
  } = useQuery({
    queryKey: ['dashboard-stats'],
    queryFn: () => callService.getDashboardStats(),
  });

  return {
    calls: callsData?.calls ?? [],
    stats,
    isStatsLoading,
    isCallsLoading,
  };
} 